window.onscroll = function () {
  const h = document.querySelector("header");
  var top = window.pageYOffset || document.documentElement.scrollTop;
  if (top > window.innerHeight - 100) {
    h.classList.add("active");
  } else {
    h.classList.remove("active");
  }
};

window.onload = function (e) {
  const vid = document.querySelector("video");
  vid
    .play()
    .then(() => {})
    .catch((error) => {
      const v = document.querySelectorAll(".tech-adv video");
      v.forEach((item) => {
        item.setAttribute("controls", "controls");
      });
    });
};
